<template>
  <div class="table-container">
    <el-table
      ref="table"
      :size="size"
      :height="height"
      :data="paginationData"
      :border="border"
      :stripe="stripe"
      style="width: 100%"
      v-loading="loading"
      :highlight-current-row="highlightCurrentRow"
      @selection-change="handleSelectionChange"
      @select="handleSelect"
      @select-all="handleSelectAll"
      @expand-change="handleExpandChange"
      @clearSelection="clearSelection"
      @sort-change="handleSortChange"
      @current-change="handleCurrentRowChange"
      :row-key="getRowKey"
      :row-style="rowStyle"
      :expand-row-keys="expands"
      :class="noSelectAll ? 'no-select-all' : ''"
      @cell-click="handleCellClick"
    >
      <el-table-column type="expand" :key="slotKey" v-if="innerTable" :label="expandData.label" :width="expandData.width">
        <slot name="innerTable"></slot>
      </el-table-column>
      <el-table-column
        v-if="selection"
        type="selection"
        :reserve-selection="reserveSelection"
        :selectable="selectable"
        width="55"
        align="center"
      >
      </el-table-column>
      <el-table-column
        v-if="rowIndex"
        type="index"
        width="50">
      </el-table-column>
      <el-table-column
        v-for="(column, index) in columns"
        :prop="column.key"
        :label="column.label"
        :width="column.width"
        :sortable="column.sortable"
        :key="index"
        :align="column.align || 'center'"
        :show-overflow-tooltip="column.showOverflowTooltip"
        :class-name="column.className"
        :fixed="column.fixed"
      >
        <el-table-column
          v-if="column.type === 'multi_header'"
          v-for="(item, index) in column.children"
          :prop="item.key"
          :label="item.label"
          :width="item.width"
          :sortable="item.sortable"
          :key="index"
          :align="item.align || 'center'"
        >
          <template slot-scope="scope">
            <span>{{ scope.row[item.key] }}</span>
          </template>
        </el-table-column>
        <template slot-scope="scope">
          <img style="cursor: pointer;" title="点击预览" v-if="column.type === 'image'" :src="scope.row[column.key]" :alt="scope.row[column.key]" @click="previewImg(scope.row[column.key])" :width="column.imgWidth" :height="column.imgHeight">
          <div style="cursor: pointer; color:#409EFF" v-else-if="column.type === 'imageText'" @click="previewImg(scope.row[column.key])" :width="column.imgWidth" :height="column.imgHeight">
            查看
          </div>
          <div style="cursor: pointer; color:#409EFF" v-else-if="column.type === 'numText'" @click="handleButtonClick(column.type, scope.row, scope.$index)" :width="column.imgWidth" :height="column.imgHeight">
            {{ scope.row[column.key] }}
          </div>
          <div class="action-con" v-else-if="column.type === 'action'">
            <!-- selectButton 表明不需要展示所有的表单按钮 -->
            <!-- 通过配置data里的btnList字段来控制 -->
            <el-button
              v-for="button in column.buttonInfos"
              v-if="column.selectButton ? scope.row.btnList.some((item) => {return item === button.name}) : true"
              :key="button.name"
              size="mini"
              :type="button.color"
              :style="button.innerStyle"
              :class="[column.multiActions ? 'multi-actions' : '']"
              @click="handleButtonClick(button.name, scope.row, scope.$index)"
              :disabled="scope.row[column.disabled] || button.disabled">{{ button.label }}</el-button>
          </div>
          <div v-else-if="column.type === 'sort'">
            <span>{{++scope.$index}}</span>
          </div>
           <!-- 下拉 -->
          <div v-else-if="column.type === 'select'">
              <el-select placeholder="请选择" v-validate="'required'" v-model="scope.row[column.key]" clearable @change="selectChange(scope, scope.row[column.key])">
                <el-option
                  v-for="select in selectData"
                  :key="select.code"
                  :label="select.value"
                  :value="select.code">
                </el-option>
              </el-select>
          </div>
          <div v-else-if="column.type === 'datatype'">
            <el-select placeholder="请选择" v-validate="'required'" v-model="scope.row[column.key]" clearable @change="selectChange(scope, scope.row[column.key])">
              <el-option
                v-for="select in typeData"
                :key="select.code"
                :label="select.value"
                :value="select.code">
              </el-option>
            </el-select>
          </div>
          <div v-else-if="column.type === 'statusChange'">
            <el-select placeholder="请选择" v-validate="'required'" v-model="scope.row[column.key]" clearable @change="selectChange(scope, scope.row[column.key])">
              <el-option
                v-for="select in isStatus"
                :key="select.code"
                :label="select.value"
                :value="select.code">
              </el-option>
            </el-select>
          </div>
          <my-render v-else-if="column.type === 'render'" :row="scope.row" :render="column.render"></my-render>
          <div v-else-if="column.type === 'url'">
            <span v-if="!scope.row[column.key]">无</span>
            <router-link class="url-a" v-else :to="{path: column.link, query: {bill_id: scope.row[column.link_name]} }">{{scope.row[column.link_name] || '跳转链接'}}</router-link>
          </div>
          <div v-else-if="column.type === 'common_url'">
            <span v-if="!scope.row[column.key] || scope.row[column.key] === '0'">{{scope.row[column.key] === '0'?'0':'无'}}</span>
            <router-link target="_blank" class="url-a" v-else :to="{path: column.link, query: {id: scope.row[column.param]} }">{{scope.row[column.key] || '跳转链接'}}</router-link>
          </div>
          <div v-else-if="column.type === 'edit'">
            <el-input-number
              class="input-num"
              :controls="false"
              size="mini"
              v-model="scope.row[column.key]"
              :disabled="scope.row.status === 1"
              ></el-input-number>
          </div>
          <div v-else-if="column.type === 'tag'">
            <span v-if="!scope.row[column.key] || scope.row[column.key].length === 0">-</span>
            <el-tag v-else v-for="(item,index) in scope.row[column.key]" :key="index" size="medium" style="margin-right:10px">{{item}}</el-tag>
          </div>
          <span v-else-if="column.type === 'html'" v-html="scope.row[column.key]"></span>
            <el-input v-else-if="column.type === 'input'"
              v-model="scope.row[column.key]"
              :readonly="column.readonly"
              :type="column.inputType ? column.inputType : 'text'"
              @change="inputHandler($event, scope.row, column.key, scope.$index)"
              @keyup.native="keyupLimit($event, scope.row, column.key, scope.$index)"
              :value="scope.row[column.key]"
              :disabled="scope.row[column.disabled]"
              :min="column.min"
              :max="column.max"
              :maxlength="column.maxlength">
              </el-input>
          <div v-else-if="column.type === 'radio'">
            <el-radio @change.native="getTemplateRow(scope.row)" :label="scope.row.package_id || scope.row.rise_package_id || scope.row.goods_id" v-model="radio" class="textRadio">&nbsp;</el-radio>
          </div>
          <div v-else-if="column.type === 'yesno'">
            <span v-if="scope.row[column.key] === true || scope.row[column.key] === '1'">是</span>
            <span v-else>-</span>
          </div>
          <div v-else-if="column.type === 'poppover'">
            <el-popover
              v-if="scope.row[column.main_key]"
              placement="top-start"
              :title="column.label"
              width="300"
              trigger="hover"
              :content="scope.row[column.main_key]">
              <el-button slot="reference" v-html="scope.row[column.key]"></el-button>
            </el-popover>
            <span v-else>-</span>
          </div>
          <div v-else-if="column.type === 'upload_status'">
              <i  v-if="scope.row[column.key] === true" class="el-icon-success" style="color: green"></i>
          </div>
          <div v-else-if="column.type === 'select_wrong'">
            <el-select size="small" v-model="scope.row[column.key]" :disabled="scope.row[column.disabled]" placeholder="请选择" @change="updateErrorOpernStatus(scope.row[column.id], scope.row[column.key], scope.$index)">
              <el-option
                v-for="item in error_opern_status"
                :key="item.code"
                :label="item.value"
                :value="item.code"
              ></el-option>
            </el-select>
          </div>
          <span v-else-if="column.type === 'line_through'" :class="scope.row.isElide ? 'elide' : ''">{{ scope.row[column.key] | filter(column.filter, column.filterProps) }}</span>
          <span v-else>{{ scope.row[column.key] | filter(column.filter, column.filterProps) }}</span>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination" v-if="showPagination">
      <el-pagination
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        :current-page="currentPage"
        layout="total, prev, pager, next, sizes"
        :page-sizes="pageSizes"
        :page-size="pageSize"
        :total="total">
      </el-pagination>
    </div>
    <el-dialog title="图片预览" width="40%" :visible.sync="showImgPreview" class="img-preview">
      <div class="image">
        <img :src="imgSrc">
      </div>
        <div slot="footer" class="search-button-container">
          <el-button type="primary" @click="showImgPreview = false">关闭</el-button>
        </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { ROW_SELECT } from '@/store/tableActions'
import MyRender from './my-render'
import Sortable from 'sortablejs'

export default {
  props: {
    isBackPage: {
      type: Boolean,
      required: false,
      default: false,
      num: 1
    },

    totalCount: {
      type: Number,
      required: false,
      default: 0
    },

    rowIndex: {
      type: Boolean,
      required: false,
      default: false
    },

    selection: {
      type: Boolean,
      required: false,
      default: false
    },

    reserveSelection: {
      type: Boolean,
      required: false,
      default: false
    },

    noSelectAll: {
      type: Boolean,
      required: false,
      default: false
    },

    data: {
      type: Array,
      required: true,
      default: () => ([])
    },

    columns: {
      type: Array,
      required: true,
      default: () => ([])
    },

    height: {
      required: false
    },

    border: {
      type: Boolean,
      required: false,
      default: true
    },

    stripe: {
      type: Boolean,
      required: false,
      default: true
    },

    defaultPageSize: {
      type: Number,
      required: false,
      default: 20
    },

    defaultcurrentPage: {
      type: Number,
      required: false,
      default: 1
    },

    loading: {
      type: Boolean,
      required: false,
      default: false
    },

    showPagination: {
      type: Boolean,
      required: false,
      default: true
    },

    innerTable: {
      type: Boolean,
      required: false,
      default: false
    },

    expands: {
      type: Array,
      required: false,
      default: () => ([])
    },

    getRowKey: {
      type: Function,
      required: false,
      default: function () {}
    },

    rowStyle: {
      type: Function,
      required: false,
      default: function () {}
    },

    expandData: {
      type: Object,
      required: false,
      default: () => ({})
    },

    isElide: {
      type: Boolean,
      required: false,
      default: false
    },

    slotKey: {
      type: Number,
      required: false,
    },

    selectData: {
      type: Array,
      required: false,
      default: () => ([])
    },

    typeData: {
      type: Array,
      required: false,
      default: () => ([])
    },

    isStatus: {
      type: Array,
      required: false,
      default: () => ([])
    },

    error_opern_status: {
      type: Array,
      required: false,
      default: () => ([])
    },
    isSelectable: { // 是否需要筛选可选项
      type: Boolean,
      required: false,
      default: false
    },
    pageSizes: {
      type: Array,
      default: () => ([10, 20, 50, 100, 500, 1000])
    },
    highlightCurrentRow: {
      type: Boolean,
      required: false,
      default: false
    },
    // 是否拖拽排序
    isDragSort: {
      type: Boolean,
      required: false,
      default: false
    },
    size: {
      type: String,
      required: false,
      default: 'medium'
    },
  },
  components: {
    MyRender
  },
  data() {
    return {
      currentPage: 1,
      pageSize: this.defaultPageSize,
      showImgPreview: false,
      imgSrc: '',
      radio: ''
    }
  },

  computed: {
    paginationData() {
      let tempData = []
      if (this.isBackPage) {
        tempData = this.data
      } else {
        let pageTotal = this.data.length,
          pageFirstCount = this.pageSize * (this.currentPage - 1),
          maxCount = pageTotal - pageFirstCount < this.pageSize ? pageTotal : (pageFirstCount + this.pageSize)

        for (let i = pageFirstCount; i < maxCount; i++) {
          tempData.push(this.data[i])
        }
      }
      return tempData
    },

    total: function() {
      return this.isBackPage ? this.totalCount : this.data.length
    }
  },

  methods: {
    ...mapActions([ROW_SELECT]),
    handleCurrentRowChange(val) {
      this.$emit('getCurrentRow', val)
    },
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage
      if (this.isBackPage) {
        this.$emit('handleCurrentChange', currentPage)
      }
    },

    handleCellClick(row, column, cell, event) {
      this.$emit('cellClick', { row, column, cell, event })
    },

    handleSizeChange(pageSize) {
      this.pageSize = pageSize
      if (this.isBackPage) {
        this.$emit('handleSizeChange', pageSize)
      }
    },
    handleAddResType(ext) {
      this.$emit('addResType', {
        ext: ext
      })
    },
    handleTypeVerUp(id) {
      this.$emit('typeVerUp', {
        id: id
      })
    },
    updateTypeVer(type, curVer) {
      this.$emit('updateTypeVer', {
        type: type,
        curVer: curVer
      })
    },
    handleButtonClick(button, row, index) {
      this.$emit('buttonClick', {
        button: button,
        data: Object.assign({}, row),
        rowIndex: index
      })
    },

    handleSelectionChange(val) {
      this.ROW_SELECT(val) // save row info in an Array into vuex.state called `rowAction`
      this.$emit('selected', val, this.currentPage)
    },

    handleSelect(selection, row) {
      this.ROW_SELECT(selection)
      this.$emit('select', {
        selection,
        row
      })
    },

    handleSelectAll(selection) {
      this.$emit('selectAll', selection)
    },

    getTemplateRow(row) {
      this.ROW_SELECT([row])
    },
    // 清空选择
    clearSelection() {
      this.$refs.table.clearSelection()
    },

    // 自定义排序
    handleSortChange(val) {
      this.$emit('sortChange', val)
    },

    handleExpandChange(row) {
      this.$emit('expandChange', row);
    },

    // 切换选择
    toggleRowSelection(row, status) {
      this.$refs.table.toggleRowSelection(row, status)
    },

    inputHandler(val, row, column, row_index) {
      this.$emit('inputChange', {
        val,
        row,
        column,
        row_index
      })
    },

    keyupLimit(val, row, column, row_index) {
      this.$emit('keyupHandlerLimit', {
        val,
        row,
        column,
        row_index
      })
    },

    previewImg(src) {
      this.imgSrc = src
      this.showImgPreview = true
    },

    updateErrorOpernStatus(id, status, row_index) {
      this.$emit('updateStatus', {
        id,
        status,
        row_index
      })
    },
    // 是否可以选中
    selectable(row) {
      if (!this.isSelectable || row.status === '1') {
        return true
      } else {
        return false
      }
    },
    //  切换下拉选择
    selectChange(id, index, key) {
      this.$emit('handleSelect', {
        id,
        index,
        key
      })
    },
    // 拖拽排序
    rowDrop() {
      const tbody = this.$refs.table.$el.querySelector('.el-table__body-wrapper tbody')
      const _this = this
      Sortable.create(tbody, {
        onEnd({ newIndex, oldIndex }) {
          let data = [..._this.data]
          data.splice(newIndex, 0, data.splice(oldIndex, 1)[0]);
          _this.$emit('update:data', [])
          _this.$nextTick(() => {
            _this.$emit('update:data', data)
            _this.$emit('dragSort')
          })
        }
      })
    },
  },

  watch: {
    paginationData: {
      immediate: false,
      handler() {
        if (this.isDragSort) {
          setTimeout(() => {
            this.rowDrop();
          }, 2000)
        }
      }
    },
    defaultcurrentPage(val) {
      this.currentPage = val
    },
    '$store.state.Table.rowAction'(val) {
      if (val.length === 0) {
        this.radio = ''
      }
    }
  }
}
</script>

<style lang="less">
.table-container{
  .pagination {
    padding: 20px 0 0;
    text-align: right;
  }
  .action-con {
    text-align: center;
  }
  .multi-actions {
    margin: 0 5px 5px 0;
  }
  .image{
    text-align: center;
  }
  .url-a{
    color: #409EFF;
    text-decoration: underline;
    cursor: pointer;
  }
  .input-num {
    text-align: center;
    width: 50px;
  }
  .elide {
    text-decoration: line-through;
  }
}
.no-select-all{
  thead th .el-checkbox__input{
    display: none;
  }
}
</style>
